import { useEffect, useState } from "react";
import { SmartAssemblyType } from "@eveworld/types/types";
import { WalletClient } from "viem";
import { deriveOrderType, deriveOrderTypeDisplay } from "@/utils/displayFormat";
import { OrderType, SavedOrder } from "@/types/orders";
import BaseOrder from "../../Order";
import { useMUD } from "@/MUDContext";
import { useEffectOnce } from "@/utils/hooks/useEffectOnce";
import { sortBigIntAscending, sortBigIntDescending } from "../../utils";
import FixedLengthTable from "@/components/FixedLengthTable";
import { useSmartObject } from "@eveworld/contexts";
import { Location as DeployableLocation } from "@/types/locations";
import {
  getSolarSystemById,
  lightYearsBetweenTwo3DPoints,
} from "@/utils/distance";

interface IState {
  locations: Record<string, DeployableLocation>;
  openPlayerOrders: SavedOrder[];
  closedPlayerOrders: SavedOrder[];
}

const PlayerOrders = ({
  characterId,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
  showTradeAssetBalance = false,
}: {
  characterId: string;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
  showTradeAssetBalance?: boolean;
}) => {
  const { smartAssembly } = useSmartObject();
  const [state, setState] = useState<IState>({
    locations: {},
    openPlayerOrders: [],
    closedPlayerOrders: [],
  });
  const {
    systemCalls: {
      getOpenPlayerOrders,
      getClosedPlayerOrders,
      getStructuresLocations,
    },
  } = useMUD();
  useEffectOnce(() => {
    if (smartAssembly === null || !smartAssembly?.id) {
      console.error("Not currently logged into a smart assembly.");
      return;
    }
    Promise.all([
      getOpenPlayerOrders(BigInt(characterId)),
      getClosedPlayerOrders(BigInt(characterId)),
    ]).then(([openOrders, closedOrders]) => {
      // console.log("closedOrders", closedOrders);
      // console.log("openOrders", openOrders);
      const smartObjIds = Array.from(
        new Set([
          ...openOrders.map((order) => order.smartObjectId),
          ...closedOrders.map((order) => order.smartObjectId),
          BigInt(smartAssembly?.id),
        ])
      );
      getStructuresLocations(smartObjIds).then((locations) => {
        console.log("locations", locations);
        setState((prevState) => ({
          ...prevState,
          locations: locations.reduce(
            (acc, cur, idx) => {
              if (!cur || !smartObjIds[idx]) {
                return acc;
              }
              acc[smartObjIds[idx].toString()] = cur;
              return acc;
            },
            {} as Record<string, DeployableLocation>
          ),
          closedPlayerOrders: closedOrders,
          openPlayerOrders: openOrders,
        }));
      });
    });
  });
  const buyOrders = state.openPlayerOrders
    .filter((order) => deriveOrderType(order.orderType) === OrderType.LIMIT_BUY)
    .sort((a, b) => sortBigIntDescending(a.createdTime, b.createdTime));
  const sellOrders = state.openPlayerOrders
    .filter(
      (order) => deriveOrderType(order.orderType) === OrderType.LIMIT_SELL
    )
    .sort((a, b) => sortBigIntDescending(a.createdTime, b.createdTime));
  const closedOrders = state.closedPlayerOrders.sort((a, b) =>
    sortBigIntDescending(a.finishedTime, b.finishedTime)
  );
  return (
    <>
      {buyOrders && buyOrders.length > 0 ? (
        <FixedLengthTable
          title="Open Buy Orders"
          headers={{
            quantity: { title: "Quantity Remaining" },
            price: { title: "Price", style: { maxWidth: "100px" } },
            location: { title: "Location" },
            distance: { title: "Distance", style: { maxWidth: "100px" } },
            createdTime: {
              title: "Created Time",
              style: { maxWidth: "80px" },
            },
          }}
          tradeAssetTicker={tradeAssetTicker}
          arrayOfRecords={buyOrders
            .map((order) => {
              const assemblyLocation =
                state.locations[order.smartObjectId.toString()];
              if (
                !assemblyLocation ||
                smartAssembly === null ||
                !smartAssembly?.id
              ) {
                return;
              }
              const currentLocation = state.locations[smartAssembly?.id];
              if (!currentLocation) {
                return;
              }
              return {
                price: order.price,
                quantity: `${order.quantityRemaining.toString()}/${order.startingQuantity.toString()}`,
                location: assemblyLocation?.solarSystemId
                  ? `${getSolarSystemById(assemblyLocation?.solarSystemId).a_name} - ${order.smartObjectId.toString().slice(0, 5)}`
                  : "Unknown",
                distance: `${lightYearsBetweenTwo3DPoints(assemblyLocation, currentLocation).toFixed(4)} LY`,
                createdTime: new Date(
                  parseInt(order.createdTime.toString()) * 1000
                ),
              };
            })
            .filter(Boolean)}
        />
      ) : (
        <>
          <div className="Quantum-Container Text">Buy Orders</div>
          <div className="Quantum-Container text-xs">No open buy orders</div>
        </>
      )}
      {sellOrders && sellOrders.length > 0 ? (
        <FixedLengthTable
          title="Open Sell Orders"
          headers={{
            quantity: { title: "Quantity Remaining" },
            price: { title: "Price", style: { maxWidth: "100px" } },
            location: { title: "Location" },
            distance: { title: "Distance", style: { maxWidth: "100px" } },
            createdTime: {
              title: "Created Time",
              style: { maxWidth: "80px" },
            },
          }}
          tradeAssetTicker={tradeAssetTicker}
          arrayOfRecords={sellOrders
            .map((order) => {
              // get smartAssembly from order smartObjectId if it exists
              const assemblyLocation =
                state.locations[order.smartObjectId.toString()];
              if (
                !assemblyLocation ||
                smartAssembly === null ||
                !smartAssembly?.id
              ) {
                return;
              }
              const currentLocation = state.locations[smartAssembly?.id];
              if (!currentLocation) {
                return;
              }
              return {
                price: order.price,
                quantity: `${order.quantityRemaining.toString()}/${order.startingQuantity.toString()}`,
                location: assemblyLocation?.solarSystemId
                  ? `${getSolarSystemById(assemblyLocation?.solarSystemId).a_name} - ${order.smartObjectId.toString().slice(0, 5)}`
                  : "Unknown",
                distance: `${lightYearsBetweenTwo3DPoints(assemblyLocation, currentLocation).toFixed(4)} LY`,
                createdTime: new Date(
                  parseInt(order.createdTime.toString()) * 1000
                ),
              };
            })
            .filter(Boolean)}
        />
      ) : (
        <>
          <div className="Quantum-Container Text">Sell Orders</div>
          <div className="Quantum-Container text-xs">No open sell orders</div>
        </>
      )}
      {closedOrders && closedOrders.length > 0 ? (
        <FixedLengthTable
          title="Closed Orders"
          headers={{
            quantity: { title: "Quantity Filled" },
            price: { title: "Price" },
            orderType: { title: "Order Type" },
            location: { title: "Location" },
            completionTime: {
              title: "Completed Time",
              style: { maxWidth: "80px" },
            },
          }}
          tradeAssetTicker={tradeAssetTicker}
          arrayOfRecords={closedOrders
            .map((order) => {
              const assemblyLocation =
                state.locations[order.smartObjectId.toString()];
              if (!assemblyLocation) {
                return;
              }
              return {
                price: order.price,
                quantity: `${(order.startingQuantity - order.quantityRemaining).toString()}/${order.startingQuantity.toString()}`,
                orderType: deriveOrderTypeDisplay(order.orderType),
                location: assemblyLocation?.solarSystemId
                  ? `${assemblyLocation?.solarSystemId} - ${assemblyLocation.solarSystemId.toString().slice(0, 5)} `
                  : "Unknown",
                completionTime: order.finishedTime
                  ? new Date(parseInt(order.finishedTime.toString()) * 1000)
                  : new Date(0),
              };
            })
            .filter(Boolean)}
        />
      ) : (
        <>
          <div className="Quantum-Container Text">Closed Orders</div>
          <div className="Quantum-Container text-xs">No closed orders</div>
        </>
      )}
    </>
  );
};
export default PlayerOrders;
